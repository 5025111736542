import React from 'react'
import { connect } from 'redux-zero/react'
import '../styles/formStyle.css'
import '@kenshooui/react-multi-select/dist/style.css'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys'
import {
  convertUnderscoreNameToLabel,
  generateID,
} from '../../../utils/taskHandler'
import Button from '../Buttons/Button'
import Input from '../FormElements/Input'
import styled from 'styled-components'
import { Radio, InputLabel, Box, Divider, Typography } from '@material-ui/core'
import MultiSelect from '@kenshooui/react-multi-select'
import { createGroup, updateGroup } from '../../../services/api'
import { ExportGroupsToCSV } from '../../../components/ExportGroupsToCSV'
import { GPTTemplate } from '../FormElements/GPT_Template'
import Select from 'react-select'

const customStyles = {
  control: provided => ({
    ...provided,
    width: '250px',
    maxWidth: '250px',
    maxHeight: '250px',
    overflowY: 'auto',
    marginBottom: '20px',
  }),
  option: (provided, state) => ({
    ...provided,
    width: '250px',
    maxWidth: '250px',
    backgroundColor: 'white',
    color: state.isDisabled ? 'grey' : 'black',
    padding: 10,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
}
import RetailTheme from '../FormElements/RetailTheme'
import { isEmpty } from 'lodash'
import { DEFAULT_THEME } from '../FormElements/RetailTheme/utils'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
function hasNonEmptyArray(obj) {
  for (let key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      return true
    }
  }
  return false
}
function transformStoreCategoriesToPayload(obj) {
  const transformed = {}
  for (const key in obj) {
    transformed[key] = obj[key].map(item => item.value)
  }
  return transformed
}

const transformStoreCategories = categories => {
  const transformed = {}
  for (const key in categories) {
    transformed[key] = categories[key].map(value => ({ label: value, value }))
  }
  return transformed
}

const calculateSidsOptionList = (storesIds, groups) => {
  return storesIds.map(sid => {
    const sidString = String(sid)
    const group = Object.values(groups).find(g => {
      return g.sid && g.sid.includes(sidString)
    })

    if (group) {
      return { value: sid, label: `(${sidString}) ${group.name}` }
    }

    return { value: sid, label: sidString }
  })
}

class GroupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this._setState() }
  }

  _setState = () => {
    return {
      uids:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.UIDS] != null
          ? this.props.selectedGroup[Keys.UIDS]
          : [],
      child:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.CHILD] != null
          ? this.props.selectedGroup[Keys.CHILD]
          : [],
      parents:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.PARENTS] != null
          ? this.props.selectedGroup[Keys.PARENTS]
          : [],
      level:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.LEVEL] != null
          ? this.props.selectedGroup[Keys.LEVEL]
          : [],
      sid:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.SID] != null
          ? this.props.selectedGroup[Keys.SID].map(sid => {
              return { label: sid }
            })
          : [],
      oid:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.OBJECT_ID] != null
          ? this.props.selectedGroup[Keys.OBJECT_ID]
          : generateID(),
      name:
        this.props.editGroup === true &&
        this.props.selectedGroup[Keys.NAME] != null
          ? this.props.selectedGroup[Keys.NAME]
          : '',
      index:
        this.props.editGroup === true
          ? this.props.selectedGroup['index']
          : null,
      groupTags:
        this.props.editGroup &&
        this.props.selectedGroup[Keys.GROUP_TAGS] &&
        this.props.retailTags.length !== 0
          ? this.props.retailTags
              .map(tag => (tag = { value: tag.sk, label: tag.name }))
              .filter(tag =>
                this.props.selectedGroup[Keys.GROUP_TAGS].includes(tag.value)
              )
          : [],
      rankingTags:
        this.props.editGroup &&
        this.props.retailTags.length !== 0 &&
        this.props.selectedGroup.config?.['ranking_tags']
          ? this.props.retailTags.filter(tag =>
              this.props.selectedGroup.config['ranking_tags'].includes(tag.sk)
            )
          : [],
      reportsTags:
        this.props.editGroup &&
        this.props.retailTags.length !== 0 &&
        this.props.selectedGroup.config?.['reports_tags']
          ? this.props.retailTags.filter(tag =>
              this.props.selectedGroup.config['reports_tags'].includes(tag.sk)
            )
          : [],
      added_child: [],
      added_users_ids: [],
      deleted_users_ids: [],
      deleted_child: [],
      gptTemplate:
        this.props.editGroup && this.props.selectedGroup?.config?.gpt_template
          ? this.props.selectedGroup?.config?.gpt_template
          : '',
      sidsOptions: calculateSidsOptionList(
        this.props.storesIds,
        this.props.groups
      ),
      report_data_config_default_options: this.props.retailsDataConfigs?.[
        this.props.selected_rid
      ]?.reports_data_configs
        ? this.props.retailsDataConfigs?.[
            this.props.selected_rid
          ]?.reports_data_configs.map(config => {
            return { value: config.sk, label: config.name }
          })
        : [],
      report_data_config:
        this.props.editGroup &&
        this.props.retailsDataConfigs?.[this.props.selected_rid]
          ?.reports_data_configs &&
        this.props.selectedGroup?.config?.['reports_data_config_id']
          ? {
              value: this.props.selectedGroup?.config?.[
                'reports_data_config_id'
              ],
              label: this.props.retailsDataConfigs?.[
                this.props.selected_rid
              ]?.reports_data_configs.find(config => {
                return (
                  config.sk ===
                  this.props.selectedGroup?.config?.['reports_data_config_id']
                )
              }).name,
            }
          : null,
      tasks_data_config_default_options: this.props.retailsDataConfigs?.[
        this.props.selected_rid
      ]?.tasks_data_configs
        ? this.props.retailsDataConfigs?.[
            this.props.selected_rid
          ]?.tasks_data_configs.map(config => {
            return { value: config.sk, label: config.name }
          })
        : [],
      tasks_data_config:
        this.props.editGroup &&
        this.props.retailsDataConfigs?.[this.props.selected_rid]
          ?.tasks_data_configs &&
        this.props.selectedGroup?.config?.['tasks_data_config_id']
          ? {
              value: this.props.selectedGroup?.config?.['tasks_data_config_id'],
              label:
                this.props.retailsDataConfigs?.[
                  this.props.selected_rid
                ]?.tasks_data_configs.find(config => {
                  return (
                    config.sk ===
                    this.props.selectedGroup?.config?.['tasks_data_config_id']
                  )
                })?.name ?? null,
            }
          : null,
      selectedDepartment:
        this.props.editGroup === true && this.props.selectedGroup.didss != null
          ? transformStoreCategories(this.props.selectedGroup.didss)
          : null,
      theme_config:
        this.props.editGroup && this.props.selectedGroup?.config?.theme_config
          ? this.props.selectedGroup?.config?.theme_config
          : DEFAULT_THEME,
      isChecked:
        this.props.editGroup && this.props.selectedGroup?.config?.theme_config
          ? true
          : false,
      config:
        this.props.editGroup &&
        this.props.selectedGroup?.config &&
        !isEmpty(this.props.selectedGroup?.config)
          ? this.props.selectedGroup?.config
          : {},
    }
  }
  handleSetChecked = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }
  handleChangeThemeConfig = config => {
    this.setState({ theme_config: config })
  }

  getTreeGroupsByGroupID = (groups, rootId, visited = new Set()) => {
    if (visited.has(rootId)) {
      return [] // Already visited, return an empty array to break the recursion
    }

    visited.add(rootId)

    const currentGroup = groups[rootId] || null
    if (!currentGroup) {
      return [] // Group not found, return an empty array
    }

    const childIds = currentGroup.child || []
    const childGroups = childIds.flatMap(id =>
      this.getTreeGroupsByGroupID(groups, id, visited)
    )

    return [currentGroup, ...childGroups]
  }

  handleChange = event => {
    try {
      let val = event.target.value
      const name = event.target.name
      if ([Keys.LEVEL].includes(name)) {
        val = Number(event.target.value)
      }
      this.setState({
        [name]: val,
      })
    } catch (e) {
      console.log(e)
    }
  }
  calculateSidsOptionList = () => {
    const { storesIds, groups } = this.props
    const sidMapping = storesIds.reduce((acc, sid) => {
      const group = Object.values(groups).find(g => {
        return g.sid && g.sid[0] === sid
      })

      if (group) {
        acc[sid] = { group_id: group.oid, name: group.name }
      } else {
        acc[sid] = null
      }

      return acc
    }, {})
    this.setState({ sidsOptions: sidMapping })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.groups !== prevProps.groups ||
      this.props.selected_rid !== prevProps.selected_rid ||
      this.props.retailsConfig !== prevProps.retailsConfig ||
      this.props.editGroup !== prevProps.editGroup ||
      this.props.storeDepartments !== prevProps.storeDepartments
    ) {
      this.setState({
        ...this._setState(),
      })
    }
  }

  handleChildChange = newGroups => {
    const chekedGroups = newGroups.map(id => id['id'])
    const addedGroups =
      this.props.selectedGroup[Keys.CHILD] != null
        ? chekedGroups.filter(
            id => !this.props.selectedGroup[Keys.CHILD].includes(id)
          )
        : chekedGroups

    const deletedUGroups =
      this.props.selectedGroup[Keys.CHILD] != null
        ? this.props.selectedGroup[Keys.CHILD].filter(
            id => !chekedGroups.includes(id)
          )
        : []

    this.setState({
      child: chekedGroups,
      added_child: addedGroups,
      deleted_child: deletedUGroups,
    })
  }

  handleUidsChange = newIds => {
    const chekedUIDs = newIds.map(id => id['id'])
    const addedUsersIds =
      this.props.selectedGroup[Keys.UIDS] != null
        ? chekedUIDs.filter(
            id => !this.props.selectedGroup[Keys.UIDS].includes(id)
          )
        : chekedUIDs

    const deletedUsersIds =
      this.props.selectedGroup[Keys.UIDS] != null
        ? this.props.selectedGroup[Keys.UIDS].filter(
            id => !chekedUIDs.includes(id)
          )
        : []
    this.setState({
      uids: chekedUIDs,
      added_users_ids: addedUsersIds,
      deleted_users_ids: deletedUsersIds,
    })
  }
  handleRankingTagsChange = selectedTags => {
    this.setState({
      rankingTags: selectedTags.map(tag => ({
        sk: tag.id,
        name: tag.label,
      })),
    })
  }
  handleReportsTagsChange = selectedTags => {
    this.setState({
      reportsTags: selectedTags.map(tag => ({ sk: tag.id, name: tag.label })),
    })
  }

  finishSubmit = () => {
    this.props.closeModal(false)
    this.props.setEditGroup(false)
  }

  handleDelete = event => {
    this.props.setShowDeleteGroupWarningModal(true)
  }

  printRadioForm = (label, options, state) => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}
      >
        <InputLabel shrink>{convertUnderscoreNameToLabel(label)}</InputLabel>
        {Object.keys(options).map(item => {
          return (
            <label key={item.toString()}>
              <Radio
                type="radio"
                value={Number(item)}
                color={'primary'}
                name={label}
                checked={state === Number(item)}
                onChange={this.handleChange}
              />
              {convertUnderscoreNameToLabel(options[item])}
            </label>
          )
        })}
      </div>
    )
  }

  handleResponse = (response, body, method) => {
    let msg = 'Added a new group!'
    let alertType = 'success'
    if (method === 'PUT') {
      msg = 'Nice! all changes in th group were saved!'
    }

    if (response.status === 200) {
      this.props.getRetailGroups()
    } else if (response.status === 401) {
      this.props.logout()
    } else if (response.status === 400) {
      msg = 'Changes were not saved, Bad data!'
      alertType = 'error'
    } else if (response.status === 500) {
      msg = '500'
      alertType = 'error'
    }
    this.props.handleAlert({
      text: msg,
      type: alertType,
    })
    this.finishSubmit()
  }

  createRetailGroup = body => {
    this.props.showFullScreenLoader()
    createGroup(this.handleResponse, body, this.props.hideFullScreenLoader)
  }
  isArraysEqualsById(array1, array2) {
    if (array1.length !== array2.length) {
      return false
    }
    if (array1.length === 0 && array2.length === 0) {
      return true
    }
    return array1.every((obj1, index) => obj1.id === array2[index].id)
  }

  handleSubmit = event => {
    event.preventDefault()
    const { editGroup, selected_rid, selectedGroup, retailTags } = this.props
    const {
      oid,
      uids,
      name,
      child,
      level,
      sid,
      rankingTags,
      reportsTags,
      added_users_ids,
      deleted_users_ids,
      added_child,
      deleted_child,
      selectedDepartment,
      groupTags,
      report_data_config,
      tasks_data_config,
      dataConfig,
    } = this.state
    let body = {
      oid: oid,
      rid: selected_rid,
    }
    const rankingTagsIds = rankingTags.map(tag => tag.sk)
    const reportsTagsIds = reportsTags.map(tag => tag.sk)
    if (editGroup) {
      // this.props.showFullScreenLoader()

      let config = { ...this.state.config }

      if (this.state.gptTemplate) {
        config.gpt_template = this.state.gptTemplate
      }
      if (!isEmpty(this.state.theme_config)) {
        config.theme_config = this.state.theme_config
      }

      if (!this.state.isChecked) {
        delete config.theme_config
      }
      if (rankingTagsIds.length > 0) {
        config.ranking_tags = rankingTagsIds
      } else {
        delete config.ranking_tags
      }

      if (reportsTagsIds.length > 0) {
        config.reports_tags = reportsTagsIds
      } else {
        delete config.reports_tags
      }

      if (report_data_config) {
        config.reports_data_config_id = report_data_config.value
      } else {
        delete config.reports_data_config_id
      }

      if (tasks_data_config) {
        config.tasks_data_config_id = tasks_data_config.value
      } else {
        delete config.tasks_data_config_id
      }

      body = {
        ...body,
        group_data: {
          name: name,
          level: level,
          config: config,
        },
        added_users_ids: added_users_ids,
        deleted_users_ids: deleted_users_ids,
        added_child: added_child,
        deleted_child: deleted_child,
      }

      if (level === 1) {
        body['group_data'][Keys.SID] =
          sid && sid.length > 0 ? sid.map(sid => sid.label) : []
      }
      if (
        (added_users_ids.length > 0 && deleted_users_ids.length > 0) ||
        (added_child.length > 0 && deleted_child.length > 0) ||
        (added_users_ids.length > 0 && deleted_child.length > 0) ||
        (added_child.length > 0 && deleted_users_ids.length > 0)
      ) {
        this.props.handleAlert({
          text: `You can't add and remove groups or users at the same time!`,
          type: 'error',
        })
        return
      }
      updateGroup(
        this.handleResponse,
        body,
        'PUT',
        this.props.hideFullScreenLoader
      )
    } else {
      if (level === 1) {
        body = {
          ...body,
          sid: sid && sid.length > 0 ? sid.map(sid => sid.label) : [],
        }
      }
      let config = { ...this.state.config }

      if (this.state.gptTemplate) {
        config.gpt_template = this.state.gptTemplate
      }
      if (!isEmpty(this.state.theme_config)) {
        config.theme_config = this.state.theme_config
      }
      if (!this.state.isChecked) {
        delete config.theme_config
      }
      if (rankingTagsIds.length > 0) {
        config.ranking_tags = rankingTagsIds
      }

      if (reportsTagsIds.length > 0) {
        config.reports_tags = reportsTagsIds
      }

      if (report_data_config) {
        config.reports_data_config_id = report_data_config.value
      }

      if (tasks_data_config) {
        config.tasks_data_config_id = tasks_data_config.value
      }

      body = {
        ...body,
        uids: uids,
        name: name,
        child: child,
        level: level,
        parent: null,

        group_data: {
          ...body.group_data,
          config: config,
        },
      }

      this.createRetailGroup(body)
    }
  }

  getUsersOptions = (uidsToConvert = null) => {
    let options = []
    if (uidsToConvert !== null) {
      uidsToConvert.forEach(uid => {
        options.push({ id: uid, label: uid })
      })
    } else {
      const { users } = this.props
      users.forEach(user => {
        options.push({ id: user[Keys.UID], label: user[Keys.UID] })
      })
    }
    return options
  }

  getChildOptions = (childToConvert = null) => {
    let options = []
    const { groups } = this.props
    if (childToConvert !== null) {
      childToConvert.forEach(oid => {
        options.push({ id: oid, label: groups[oid][Keys.NAME] })
      })
    } else {
      Object.keys(groups).forEach(oid => {
        options.push({ id: oid, label: groups[oid][Keys.NAME] })
      })
    }
    return options
  }
  getTagsOptions = (childToConvert = null) => {
    let options = []
    const { retailTags } = this.props
    if (childToConvert !== null) {
      childToConvert.forEach(tag => {
        options.push({ id: tag.sk, label: tag.name })
      })
    } else {
      retailTags.forEach(tag => {
        options.push({ id: tag.sk, label: tag.name })
      })
    }
    return options
  }

  handleChangeTemplate = e => {
    this.setState({ gptTemplate: e.target.value })
  }

  render() {
    const { editGroup, groupLevelMap, selectedGroup } = this.props
    const {
      name,
      child,
      uids,
      oid,
      level,
      sid,
      rankingTags,
      reportsTags,
      sidsOptions,
      groupTags,
      report_data_config,
      report_data_config_default_options,
      tasks_data_config,
      tasks_data_config_default_options,
    } = this.state

    return (
      <div className="itemForm">
        <div className="fullScreenButtonDash">
          <div
            style={{ display: 'flex', alignItems: 'center', columnGap: '30px' }}
          >
            {Object.keys(this.props.groups).length > 0 &&
              this.props.selectedGroup?.['oid'] && (
                <ExportGroupsToCSV
                  dataObject={this.getTreeGroupsByGroupID(
                    this.props.groups,
                    this.props.selectedGroup?.['oid']
                  )}
                  rid={this.props.selected_rid}
                  selectedGroup={this.props.selectedGroup.name}
                  tags={this.props.retailTags}
                />
              )}

            <Typography>
              Export to CSV all child groups of this group
            </Typography>
          </div>
          <div>
            <Button
              label={editGroup ? 'Save Group Changes' : 'Add Group'}
              type="submit"
              onClick={this.handleSubmit}
            />
            {editGroup ? (
              <Button
                type="button"
                onClick={this.handleDelete}
                color={'secondary'}
                label={'Delete'}
              />
            ) : null}
          </div>
        </div>
        {groupTags.length !== 0 && (
          <div style={{ marginTop: '20px' }}>
            <Row
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingBottom: '12px',
              }}
            >
              <span>Reports Tags List</span>
            </Row>
            <Divider light />
            <div
              style={{
                width: '100%',
                margin: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {groupTags?.map((el, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: 'center',
                      padding: '10px',
                      margin: '10px',
                      borderRadius: '20px',
                      background: '#C3BEC1',
                      minWidth: '140px',
                      width: 'max-content',
                    }}
                  >
                    {el.label}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <Row
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        />
        <Row
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <span>Group Profile</span>
        </Row>
        <Divider light />
        <Row className={'first'}>
          <Input
            key={0}
            value={oid}
            onChange={this.handleChange}
            type="text"
            name={Keys.UID}
            required={true}
            readOnly={true}
            label={'Group ID'}
          />

          {this.printRadioForm(Keys.LEVEL, groupLevelMap, level)}
          <Box>
            <Input
              key={1}
              value={name}
              onChange={this.handleChange}
              type="text"
              name={Keys.NAME}
              required={true}
              label={'Group Name'}
            />

            {level === 1 && (
              <>
                {this.props.storesIds && this.props.storesIds?.length > 0 && (
                  <>
                    <Box>
                      <Typography
                        style={{ fontSize: '16px', color: '#0000008a' }}
                      >
                        Select Store ID
                      </Typography>
                      <Select
                        value={sid}
                        options={sidsOptions}
                        isMulti
                        isClearable={true}
                        isSearchable={true}
                        isOptionDisabled={option =>
                          !!option.label && option.label.includes('(')
                        }
                        styles={customStyles}
                        onChange={option =>
                          this.setState({
                            sid: option,
                          })
                        }
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Row>
        <Row>
          <>
            {report_data_config_default_options.length > 0 && (
              <Box>
                <Typography style={{ fontSize: '16px', color: '#0000008a' }}>
                  Report Configuration
                </Typography>
                <Select
                  value={report_data_config}
                  options={report_data_config_default_options}
                  isClearable={true}
                  isSearchable={true}
                  styles={customStyles}
                  onChange={option =>
                    this.setState({
                      report_data_config: option,
                    })
                  }
                />
              </Box>
            )}
            {tasks_data_config_default_options.length > 0 && (
              <Box>
                <Typography style={{ fontSize: '16px', color: '#0000008a' }}>
                  Tasks Configuration
                </Typography>
                <Select
                  value={tasks_data_config}
                  options={tasks_data_config_default_options}
                  isClearable={true}
                  isSearchable={true}
                  styles={customStyles}
                  onChange={option =>
                    this.setState({
                      tasks_data_config: option,
                    })
                  }
                />
              </Box>
            )}
          </>
        </Row>
        <Row
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <span>Users in Group</span>
          {editGroup && (
            <span style={{ color: 'red' }}>
              You can't add and remove users at the same time *
            </span>
          )}
        </Row>
        <Divider light />
        <div
          style={{
            padding: '16px',
          }}
        >
          <MultiSelect
            onChange={this.handleUidsChange}
            selectedItems={this.getUsersOptions(uids)}
            items={this.getUsersOptions()}
          />
        </div>
        <Row
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <span>Sub Groups</span>
          {editGroup && (
            <span style={{ color: 'red' }}>
              You can't add and remove groups at the same time *
            </span>
          )}
        </Row>
        <Divider light />
        <div
          style={{
            padding: '16px',
          }}
        >
          <MultiSelect
            onChange={this.handleChildChange}
            selectedItems={this.getChildOptions(child)}
            items={this.getChildOptions()}
          />
        </div>
        {!!this.props.selectedGroup?.config?.sub_retail_id && (
          <>
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '12px',
              }}
            >
              <span>Ranking Tags</span>
            </Row>
            <Divider light />
            <div
              style={{
                padding: '16px',
              }}
            >
              <MultiSelect
                onChange={this.handleRankingTagsChange}
                selectedItems={this.getTagsOptions(rankingTags)}
                items={this.getTagsOptions()}
              />
            </div>
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '12px',
              }}
            >
              <span>Reports Tags</span>
            </Row>
            <Divider light />
            <div
              style={{
                padding: '16px',
              }}
            >
              <MultiSelect
                onChange={this.handleReportsTagsChange}
                selectedItems={this.getTagsOptions(reportsTags)}
                items={this.getTagsOptions()}
              />
            </div>
          </>
        )}

        <div>
          <RetailTheme
            setTheme={this.handleChangeThemeConfig}
            theme={this.state.theme_config}
            selected_rid={this.props.selected_rid}
            isChecked={this.state.isChecked}
            setIsChecked={this.handleSetChecked}
            isShowCheckbox={true}
          />
        </div>

        <GPTTemplate
          gptTemplate={this.state.gptTemplate}
          handleChange={this.handleChangeTemplate}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  groups,
  selectedGroup,
  users,
  editGroup,
  selected_rid,
  selected_sid,
  storesIds,
  subRidIds,
  permissions,
  locales,
  loading,
  userRidsDetails,
  userAreas,
  selectedUser,
  showUserModal,
  userRoles,
  editUser,
  openStoreSelector,
  cdn,
  avatar_path,
  userStatusMap,
  groupLevelMap,
  retailTags,
  retailsConfig,
  storeDepartments,
  dataConfigs,
  retailsDataConfigs,
}) => ({
  groups,
  selectedGroup,
  users,
  editGroup,
  selected_rid,
  loading,
  selected_sid,
  permissions,
  locales,
  userRidsDetails,
  userAreas,
  selectedUser,
  showUserModal,
  userRoles,
  editUser,
  openStoreSelector,
  cdn,
  avatar_path,
  userStatusMap,
  groupLevelMap,
  retailTags,
  subRidIds,
  retailsConfig,
  storesIds,
  storeDepartments,
  dataConfigs,
  retailsDataConfigs,
})

export default connect(mapStateToProps, actions)(GroupForm)
